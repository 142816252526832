$(document).ready(function() {

    var $address = $('.modal').find('address'),
        map,
        placemark;

    $.getScript('https://api-maps.yandex.ru/2.1/?lang=ru_RU', function () {
        ymaps.ready(function () {
            $address.on('click', function() {
                var $this = $(this);

                $address.removeClass('text-red');
                $this.addClass('text-red');

                map && map.destroy();

                map = new ymaps.Map('map', {
                    center: [$this.data('point-x'), $this.data('point-y')],
                    zoom: 15,
                    controls: ['zoomControl']
                });

                placemark = new ymaps.Placemark(map.getCenter(), {
                    hintContent: $this.data('address')
                }, {
                    iconLayout: 'default#image',
                    //iconImageHref: 'img/marker.png',
                    iconImageSize: [35, 48]
                });

                map.geoObjects.add(placemark);
            });
            $address.eq(0).trigger('click');
        });
    });

});