$(document).ready(function() {

    var isMobile,
        isTablet,
        isDesktop,
        $window = $(window),
        $body = $('html, body'),
        size = function() {
            var width = $window.width();
            isMobile = width < 768;
            isTablet = width > 767 && width < 992;
            isDesktop = width > 991;
        };

    $window.on('resize', size);
    size();

    $('#menu').find('a').on('click', function () {
        $body.stop().animate({
            scrollTop: $($(this).attr('href')).offset().top - (isMobile ? 50 : 99)
        }, 'normal');

        return false;
    });

});