$(document).ready(function() {

    var isMobile,
        isTablet,
        isDesktop,
        isLargeDesktop,
        $window = $(window),
        width,
        size = function() {
            width = $window.width();
            isMobile = width < 768;
            isTablet = width > 767 && width < 992;
            isDesktop = width > 991 && width < 1200;
            isLargeDesktop = width > 1199;
        };

    $window.on('resize', size);
    size();

    var $el = $('#client-list');

    var isRendered = false,
        isDrag = false,
        padding = 20,
        data,
        force,
        nodes,
        client;

    var height,
        scale,
        centerX,
        centerY,
        area = { minX: 0, maxX: 0, minY: 0, maxY: 0 },
        delta = { x: 0, y: 0 },
        active;

    $el.on({
        mousemove: function(e) {
            if ( isDrag ) {
                if (!delta.x || !delta.y){
                    delta.x = e.pageX;
                    delta.y = e.pageY;
                }

                var diff;
                diff = e.pageX - delta.x;
                if (
                    (area.minX < 0 && area.maxX > width) ||
                    (area.minX >= 0 && diff < 0) ||
                    (area.maxX <= width && diff > 0)
                ) {
                    centerX += diff;
                    delta.x = e.pageX;
                }

                diff = e.pageY - delta.y;
                if (
                    (area.minY < 0 && area.maxY > height) ||
                    (area.minY >= 0 && diff < 0) ||
                    (area.maxY <= height && diff > 0)
                ) {
                    centerY += diff;
                    delta.y = e.pageY;
                }

                force.start();
            }
        },
        mousedown: function() {
            isDrag = true;
            $el.css('cursor', 'move');
        },
        mouseup: function() {
            isDrag = false;
            delta.x = 0;
            delta.y = 0;
            $el.css('cursor', 'default');
        },
        mouseleave: function() {
            isDrag = false;
            $el.css('cursor', 'default');
        },
        touchmove: function(e) {
            if ( isDrag ) {
                if (!delta.x || !delta.y){
                    delta.x = e.touches[0].clientX;
                    delta.y = e.touches[0].clientY;
                }

                var diff;
                diff = e.touches[0].clientX - delta.x;
                if (
                    (area.minX < 0 && area.maxX > width) ||
                    (area.minX >= 0 && diff < 0) ||
                    (area.maxX <= width && diff > 0)
                ) {
                    centerX += diff;
                    delta.x = e.touches[0].clientX;
                }

                diff = e.touches[0].clientY - delta.y;
                if (
                    (area.minY < 0 && area.maxY > height) ||
                    (area.minY >= 0 && diff < 0) ||
                    (area.maxY <= height && diff > 0)
                ) {
                    centerY += diff;
                    delta.y = e.touches[0].clientY;
                }

                force.start();

                return false;
            }
        },
        touchstart: function() {
            isDrag = true;
        },
        touchend: function() {
            isDrag = false;
            delta.x = 0;
            delta.y = 0;
        }
    });

    $.getJSON('data/client.json', function(clients) {
        data = clients || [];
        $window.on('scroll', init).on('resize', function() {
            isRendered = false;
            init();
        });
        init ();
    });

    function init () {
        height = isMobile ? 400 : isTablet ? 700 : isDesktop ? 500 : 820;
        scale = isMobile ? 0.5 : isTablet ? 0.7 : isDesktop ? 0.7 : 1;
        centerX = width / 2;
        centerY = height / 2;

        var elOffset = $el.offset().top,
            elHeight = $el.height();

        var wOffset = $window.scrollTop(),
            wHeight = $window.height();

        if (wOffset + wHeight > elOffset + elHeight / (isMobile ? 8 : (isTablet ? 4 : 2))) {
            if (!isRendered) {
                isRendered = true;
                render();
            }
        } else {
            if(!isMobile) {
                isRendered = false;
                $el.empty();
            }
        }
    }

    function render() {
        $el.empty();

        nodes = (data || []).map(function (node) {
            return {
                name: node.name,
                type: node.type,
                services: node.services,
                size: node.size,
                cls: 'client-circle client-circle-size-' + node.size + ( node.servicesBig ? ' services-big' : '' ),

                radiusDefault: 35 * node.size,
                radiusActive: 220,

                nameOffset: node.nameOffset || -50,
                typeOffset: node.typeOffset || 0,
                servicesOffset: node.servicesOffset || 60,

                cx: centerX,
                cy: centerY
            };

        });

        force = d3.layout.force()
            .nodes(nodes)
            .size([width, height])
            .gravity(0)
            .charge(0)
            .on('tick', tick);

        var svg = d3.select('#client-list')
            .append('svg')
            .attr('width', width)
            .attr('height', height);

        client = svg.selectAll('.client-circle')
            .data(nodes)
            .enter()
            .append('g')
            .attr('class', function (d) {return d.cls;})
            .on('click', clientClick);

        client
            .append('circle')
            .attr('r', function (d) {return d.radiusDefault;});

        client
            .append('g')
            .attr('class', 'name')
            .each(function(d) { split(this, d.name, 4) });

        client
            .append('g')
            .attr('class', 'description');

        client.select('.description')
            .append('g')
            .attr('class', 'type')
            .each(function(d) { return split(this, d.type, 2) });

        client.select('.description')
            .append('g')
            .attr('class', 'services')
            .each(function(d) { return split(this, d.services, 3) });

        clientClick();
    }

    function split(group, str, lineHeight) {
        if (!str) {
            return;
        }

        var lines = str.split('\n');
        var txt;
        for( var i = 0, len = lines.length; i < len; i++ ) {
            txt = document.createElementNS("http://www.w3.org/2000/svg", 'text');
            txt.setAttribute('dy', (i * lineHeight * 10) + 'px');
            txt.textContent = lines[i].trim();
            group.appendChild(txt);
        }
    }

    function clientClick(d) {
        var last = client.size() - 1;

        d = d || {};

        client
            .attr('class', function(obj, index) {
                if (d.index === index) {
                    if ( active !== index ) {
                        active = d.index;
                        obj.radius = obj.radiusActive;
                        return obj.cls + ' active';
                    } else {
                        active = null;
                    }
                }

                obj.radius = obj.radiusDefault;
                return obj.cls;
            });
        client
            .select('circle')
            .attr('r', function(d) {return d.radius;});
        client
            .select('.name')
            .attr('transform', function(d, index) {
                var offset = 0;
                if (active === index) {
                    offset = d.nameOffset;
                }
                return 'translate(0, ' + offset + ')'
            });
        client
            .select('.type')
            .attr('transform', function(d, index) {
                var offset = 0;
                if (active === index) {
                    offset = d.typeOffset;
                }
                return 'translate(0, ' + offset + ')'
            });
        client
            .select('.services')
            .attr('transform', function(d, index) {
                var offset = 0;
                if (active === index) {
                    offset = d.servicesOffset;
                }
                return 'translate(0, ' + offset + ')'
            })
            .each(function(obj, index) {
                if ( index === last ) {
                    force.start();
                }
            });
    }

    function tick(e) {

        area.minX = 0;
        area.maxX = width;
        area.minY = 0;
        area.maxY = height;

        client
            .each(gravity(.01 * e.alpha))
            .each(collide(0.1))
            .attr('transform', function(d) {
                if ( d.px > area.maxX ) {
                    area.maxX = d.px;
                }

                if ( d.px < area.minX ) {
                    area.minX = d.px;
                }

                if ( d.py > area.maxY ) {
                    area.maxY = d.py;
                }

                if ( d.py < area.minY ) {
                    area.minY = d.py;
                }

                return 'translate(' + d.x + ',' + d.y + ') scale(' + scale + ')'
            });
    }

    function gravity(alpha) {
        return function (d) {
            d.y += (centerY - d.y) * alpha;
            d.x += (centerX - d.x) * alpha;
        };
    }

    function collide(alpha) {
        var quadTree = d3.geom.quadtree(nodes);

        return function (d) {
            var r = (d.radius + padding) * scale,
                nx1 = d.x - r,
                nx2 = d.x + r,
                ny1 = d.y - r,
                ny2 = d.y + r;

            quadTree.visit(function (quad, x1, y1, x2, y2) {
                if (quad.point && (quad.point !== d)) {

                    var x = d.x - quad.point.x,
                        y = d.y - quad.point.y,
                        l = Math.sqrt(x * x + y * y),
                        r = (d.radius + quad.point.radius + padding) * scale;

                    if (l < r) {
                        l = (l - r) / l * alpha;
                        d.x -= x *= l;
                        d.y -= y *= l;
                        quad.point.x += x;
                        quad.point.y += y;
                    }
                }

                return x1 > nx2 || x2 < nx1 || y1 > ny2 || y2 < ny1;
            });
        };
    }

});