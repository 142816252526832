$(document).ready(function() {

    var isMobile,
        isTablet,
        isDesktop,
        $window = $(window),
        size = function() {
            var width = $window.width();
            isMobile = width < 768;
            isTablet = width > 767 && width < 992;
            isDesktop = width > 991;
        };

    $window.on('resize', size);
    size();

    var $waiting = $('.waiting');
    var animate = function () {
        var wOffset = $window.scrollTop(),
            wHeight = $window.height();

        $waiting.each(function(index, el) {
            var $el = $(el),
                elOffset = $el.offset().top,
                elHeight = $el.height(),
                delay = isMobile ? 0 : ($el.data('delay') || 0) * 1000;

            if (isTablet && $el.hasClass('waiting-video')) {
                return;
            }

            if (wOffset + wHeight > elOffset + elHeight / (isMobile ? 8 : (isTablet ? 4 : 2))) {
                setTimeout(function() {
                    $el.removeClass('waiting')
                }, delay);
            } else {
                $el.addClass('waiting')
            }
        });
    };
    $window.on('scroll', animate).on('resize', animate);

});