$(document).ready(function() {

    var $body = $('html, body');

    $('.logo,.name').on('click', function () {
        $body.stop().animate({
            scrollTop: 0
        }, 'normal');

        return false;
    });

});