$(document).ready(function() {

    if (/iPad/i.test(navigator.userAgent)) {
        $('video').prop('controls', true).on('play', function() {
            var $text = $(this).prev();

            setTimeout(function(){
                $text.removeClass('waiting');
            }, ($text.data('delay') || 0) * 1000);
        });
    }

});