$(document).ready(function() {

    var isMobile,
        isTablet,
        isDesktop,
        $window = $(window),
        size = function() {
            var width = $window.width();
            isMobile = width < 768;
            isTablet = width > 767 && width < 992;
            isDesktop = width > 991;
        };

    $window.on('resize', size);
    size();

    var $slider = $('.slider'),
        $arrow = $slider.find('.arrow'),
        $year = $slider.find('.number'),
        $yearActive = $year.not('.disabled'),
        $slide = $slider.find('.slide'),
        min = $yearActive.eq(0).index(),
        max = $yearActive.eq($yearActive.length - 1).index(),
        active = function(index) {
            if (isNaN(index)) {
                index = max
            }

            index = index < min ? max : index;
            index = index > max ? min : index;

            var offset = isTablet ? 3 : 4;
            if (isMobile) {
                var width = $window.width();
                if (width > 666) {
                    offset = 3
                } else if(width > 479) {
                    offset = 2
                } else {
                    offset = 1;
                }

            }

            $year
                .removeClass('active')
                .css('transform', 'translateX(' + (- 180 * (index - offset)) + '%)')
                .eq(index)
                .addClass('active');

            $slide
                .removeClass('active')
                .css('transform', 'translateX(' + (- 100 * (index - min - (isMobile ? 0 : 1))) + '%)')
                .eq(index - min)
                .addClass('active');
        };

    $yearActive.on('click', function() {
        active($(this).index());
    });

    $arrow.on('click', function() {
        active($year.filter('.active').index() + ($(this).hasClass('arrow-left') ? -1 : 1));
    });

    $window.on('resize', active);
    active();

});