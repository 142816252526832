$(document).ready(function() {

    $('.balls > div').each(function(index, el) {
        var $ball = $(el),
            options = $.extend({
                x: 0,
                y: 0,
                amplitude: 500,
                speed: 1000
            }, $ball.data()),
            up = function () {
                $ball.animate({
                    marginTop: options.y - options.amplitude
                },options.speed, 'linear', down);
            },
            down = function () {
                $ball.animate({
                    marginTop: options.y + options.amplitude
                }, options.speed, 'linear', up);
            };

        $ball.css({
            marginTop: options.y,
            marginLeft: options.x
        });

        options.amplitude > 0 ? down() : up();
    });

});